import * as React from "react";
import {
  Modal,
  Loader,
  Button,
  Dropdown,
  List,
  Container,
  Header,
  Divider,
} from "semantic-ui-react";
import {
  PropertiesQuery,
  PropertiesQueryVariables,
  AssignDocumentToPropertyMutation,
  AssignDocumentToPropertyMutationVariables,
  DocumentByIdQuery,
  DocumentByIdQueryVariables,
  UnassignDocumentFromPropertyMutation,
  UnassignDocumentFromPropertyMutationVariables,
  Property,
} from "../../../types";
import { useMutation, useLazyQuery } from "react-apollo";
import { getProperties } from "../../queries/propertyQueries";
import {
  unassignDocumentFromProperty,
  assignDocumentToProperty,
  getDocumentById,
} from "../../queries/documentQueries";
import { toast } from "react-toastify";
import styled from "styled-components";
import { notEmpty } from "../../utils/array";

interface DocumentAssignmentModalProps {
  open: boolean;
  documentId?: string;
  loading: boolean;
  close: () => void;
  refetch: () => void;
}

const NoResultsContainer = styled.div`
  margin: 30px;
`;

const ListRowWrapper = styled.div`
  display: flex;
  margin: 10px;
`;

const LeftSideRow = styled.div`
  margin-right: auto;
`;

const RightSideRow = styled.div`
  margin-left: auto;
`;

export const DocumentAssignmentModal = (
  props: DocumentAssignmentModalProps
) => {
  // Local state to track assignments
  const [selectedPropertyId, setselectedProperty] = React.useState<
    string | undefined
  >();

  /* Mutations */

  // Mutation: Assign owner to property
  const [assignDocumentToPropertyMutation] = useMutation<
    AssignDocumentToPropertyMutation,
    AssignDocumentToPropertyMutationVariables
  >(assignDocumentToProperty, {
    onCompleted(data) {
      documentData.refetch();
      toast.success("Aðgerð heppnaðist: Leyfði húsfélagi að skoða skjal.");
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  // Mutation: Unassign owner to property
  const [unassignDocumentFromPropertyMutation] = useMutation<
    UnassignDocumentFromPropertyMutation,
    UnassignDocumentFromPropertyMutationVariables
  >(unassignDocumentFromProperty, {
    onCompleted(data) {
      documentData.refetch();
      toast.success("Aðgerð heppnaðist: Leyfi tekið af húsfélagi");
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  /* Queries */

  // Query: Get Properties
  const [getPropertiesQuery, propertiesData] = useLazyQuery<
    PropertiesQuery,
    PropertiesQueryVariables
  >(getProperties, {
    variables: {
      limit: 1000,
      page: 0,
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  // Query: Get Properties
  const [getDocumentByIdQuery, documentData] = useLazyQuery<
    DocumentByIdQuery,
    DocumentByIdQueryVariables
  >(getDocumentById, {
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  // Effects
  React.useEffect(() => {
    props.documentId && getPropertiesQuery();
  }, [props.documentId, getPropertiesQuery]);

  React.useEffect(() => {
    props.documentId &&
      getDocumentByIdQuery({
        variables: {
          id: props.documentId || "",
        },
      });
  }, [props.documentId, getDocumentByIdQuery]);

  const propertiesAssignedToDocument: Property[] =
    documentData.data &&
    documentData.data.documentById &&
    documentData.data.documentById.properties
      ? documentData.data.documentById.properties
      : [];

  // Render
  return (
    <Modal open={props.open}>
      <Loader active={props.loading && propertiesData.loading} />
      <Modal.Header>Húsfélög með aðgang að skjali</Modal.Header>
      <Modal.Content>
        {propertiesAssignedToDocument.length > 0 ? (
          <List divided verticalAlign="middle">
            {propertiesAssignedToDocument.map((property, index) => (
              <List.Item key={index}>
                <List.Icon
                  name="building"
                  size="large"
                  verticalAlign="middle"
                />
                <List.Content>
                  <ListRowWrapper>
                    <LeftSideRow>
                      <List.Header as="h3">{property.address}</List.Header>
                      <List.Description as="p">{property.id}</List.Description>
                    </LeftSideRow>
                    <RightSideRow>
                      <Button
                        color="red"
                        onClick={() =>
                          props.documentId &&
                          unassignDocumentFromPropertyMutation({
                            variables: {
                              documentId: props.documentId,
                              propertyId: property.id,
                            },
                          })
                        }
                      >
                        Eyða aðgengi
                      </Button>
                    </RightSideRow>
                  </ListRowWrapper>
                </List.Content>
              </List.Item>
            ))}
          </List>
        ) : (
          <Container textAlign="center">
            <NoResultsContainer>
              <Header>Ekkert húsfélag hefur aðgang að þessu skjali.</Header>
            </NoResultsContainer>
          </Container>
        )}

        <Divider />

        <Button as="div" labelPosition="left">
          <Dropdown
            search
            placeholder="Veldu húsfélag til að gefa aðgang að skjali"
            options={
              propertiesData.data && propertiesData.data.properties
                ? propertiesData.data.properties.data
                    .filter(notEmpty)
                    .filter(
                      (f) =>
                        !propertiesAssignedToDocument
                          .map((p) => p.id)
                          .includes(f.id)
                    )
                    .map((property) => {
                      return {
                        key: property ? property.id : "",
                        value: property ? property.id : "",
                        flag: "house",
                        text: property ? property.address : "",
                      };
                    })
                : []
            }
            selection
            onChange={(_, { value }) => {
              setselectedProperty((value && value.toString()) || "");
            }}
            className="label"
          />
          <Button
            onClick={() => {
              props.documentId &&
                selectedPropertyId &&
                assignDocumentToPropertyMutation({
                  variables: {
                    documentId: props.documentId,
                    propertyId: selectedPropertyId,
                  },
                });
            }}
            labelPosition="left"
            primary
            icon="add"
            content="Gefa aðgang að skali"
          />
        </Button>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => props.close()}
          labelPosition="right"
          icon="checkmark"
          content="Loka"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default DocumentAssignmentModal;
