import * as React from "react";
import {
  Modal,
  Button,
  Form,
  Icon,
  List,
  Select,
  Popup,
  Dropdown,
} from "semantic-ui-react";
import { useMutation, useLazyQuery } from "react-apollo";
import { useDropzone } from "react-dropzone";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import {
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables,
  PropertiesQuery,
  PropertiesQueryVariables,
  AssignDocumentToPropertyMutation,
  AssignDocumentToPropertyMutationVariables,
  UploadDocumentsV2Mutation,
  UploadDocumentsV2MutationVariables,
} from "../../../types";
import {
  updateDocument,
  assignDocumentToProperty,
  uploadDocumentsV2,
} from "../../queries/documentQueries";
import styled from "styled-components";
import DatePicker, { registerLocale } from "react-datepicker";
import is from "date-fns/locale/is";
import { getKnownDocumentTypesOptions } from "../../utils/document";
import { getFontAwesomeIconFromMIME } from "../../utils/fileicons";
import { getProperties } from "../../queries/propertyQueries";
import { notEmpty } from "../../utils/array";

/* hack to get react-datepicker-wrapper styled properly inside a list content block.  */
const StyledDatePickerWrapper = styled.div`
  display: inline;
  input {
    margin-top: -11px !important;
  }
`;

const StyledListContent = styled(List)`
  .content {
    overflow-wrap: anywhere;
  }
`;

interface DocumentUploadModalProps {
  open: boolean;
  close: () => void;
  onUpdated: () => void;
}

registerLocale("is", is);

const UploadContainer = styled.div`
  height: 200px;
  background-color: #f8f8f8;
  text-align: center;
  padding-top: 60px;
`;

export const DocumentUploadModal = (props: DocumentUploadModalProps) => {
  const [recentlyUploadedDocuments, setRecentlyUploadedDocuments] = useState<
    {
      id: string;
      name: string;
      mimetype: string;
      type: string;
      description: string;
      displayDate: string;
    }[]
  >([]);

  const [assignmentChoices, setAssignmentChoices] = useState<
    {
      id: string;
      propertyId: string;
    }[]
  >([]);

  /* Queries */

  // Query: Get Properties
  const [getPropertiesQuery, propertiesResult] = useLazyQuery<
    PropertiesQuery,
    PropertiesQueryVariables
  >(getProperties, {
    variables: {
      limit: 1000,
      page: 0,
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  /* Mutations */

  // Mutaton: Upload Documents
  const [uploadDocumentsMutation] = useMutation<
    UploadDocumentsV2Mutation,
    UploadDocumentsV2MutationVariables
  >(uploadDocumentsV2, {
    onCompleted(data) {
      setRecentlyUploadedDocuments(
        data.uploadDocumentsV2?.map((doc) => {
          return {
            id: doc.id,
            name: doc.name,
            mimetype: doc.mimetype,
            type: doc.type,
            description: doc.description,
            displayDate: doc.displayDate,
          };
        }) || []
      );
      setAssignmentChoices([]);
      toast.success("Aðgerð heppnaðist: Upphalaði skjali..");
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  // Mutation: Update Document By Id.
  const [updateDocumentMutation] = useMutation<
    UpdateDocumentMutation,
    UpdateDocumentMutationVariables
  >(updateDocument, {
    onCompleted() {
      toast.success("Aðgerð heppnaðist: Skjal uppfært");
    },
    onError(...error) {
      toast.error("Aðgerð heppnaðist ekki: " + error.map((x) => x.message)[0]);
    },
  });

  const [assignDocumentToPropertyMutation] = useMutation<
    AssignDocumentToPropertyMutation,
    AssignDocumentToPropertyMutationVariables
  >(assignDocumentToProperty, {
    onCompleted(data) {
      toast.success("Aðgerð heppnaðist: Leyfði húsfélagi að skoða skjal.");
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validity = e.target.validity;
    const files = e.target.files ? Array.from(e.target.files) : [];
    validity.valid &&
      uploadDocumentsMutation({
        variables: { files: files, type: "", description: "" },
      }).then(() => {
        props.onUpdated();
      });
  };

  // Effects
  React.useEffect(() => {
    if (props.open) getPropertiesQuery();
  }, [getPropertiesQuery, props.open]);

  return (
    <Modal open={props.open}>
      <Modal.Header>Hlaða upp skjölum</Modal.Header>
      <Modal.Content>
        <Form>
          {recentlyUploadedDocuments.length > 0 && (
            <>
              <h3>Upphlaðin skjöl:</h3>
              <StyledListContent verticalAlign="middle">
                {recentlyUploadedDocuments.map((file, index) => (
                  <List.Item key={index}>
                    <List.Content floated="right">
                      <Select
                        placeholder="Skjalaflokkur"
                        options={getKnownDocumentTypesOptions()}
                        onChange={(_, { value }) => {
                          setRecentlyUploadedDocuments(
                            recentlyUploadedDocuments.map((item, i) =>
                              index === i
                                ? { ...item, type: (value as string) || "" }
                                : item
                            )
                          );
                          updateDocumentMutation({
                            variables: {
                              id: file.id || "",
                              document: {
                                name: file.name || "",
                                description: file.description || "",
                                type: value ? value.toString() : "",
                                displayDate:
                                  file.displayDate || new Date().toISOString(),
                              },
                            },
                          });
                        }}
                      />{" "}
                      <Dropdown
                        placeholder="Heimild húsfélags"
                        selection
                        search
                        options={(propertiesResult?.data?.properties.data || [])
                          .filter(notEmpty)
                          .map((property) => {
                            return {
                              key: property.id,
                              text: property.address,
                              value: property.id,
                            };
                          })}
                        onChange={(_, data) => {
                          data.value &&
                          assignmentChoices.find((opt) => opt.id === file.id)
                            ? setAssignmentChoices(
                                assignmentChoices.map((choice) =>
                                  file.id === choice.id
                                    ? {
                                        ...choice,
                                        propertyId:
                                          data.value?.toString() || "",
                                      }
                                    : choice
                                )
                              )
                            : data.value &&
                              setAssignmentChoices([
                                ...assignmentChoices,
                                {
                                  id: file.id,
                                  propertyId: data.value.toString(),
                                },
                              ]);
                        }}
                      />{" "}
                      <Popup
                        content="Gefa völdu húsfélagi aðgang að skjalinu"
                        trigger={
                          <Button
                            icon="save"
                            primary
                            disabled={
                              !assignmentChoices.find((p) => p.id === file.id)
                            }
                            onClick={() => {
                              const choosenProperty = assignmentChoices.find(
                                (c) => c.id === file.id
                              );
                              choosenProperty &&
                                assignDocumentToPropertyMutation({
                                  variables: {
                                    documentId: file.id,
                                    propertyId: choosenProperty.propertyId,
                                  },
                                }).then(() => {
                                  // Clear all last choosen properties
                                  setAssignmentChoices(
                                    assignmentChoices.filter(
                                      (choice) =>
                                        choice.id !== choosenProperty.id
                                    )
                                  );
                                });
                            }}
                          ></Button>
                        }
                      />
                      <StyledDatePickerWrapper>
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          locale="is"
                          selected={
                            new Date(
                              recentlyUploadedDocuments[index].displayDate
                            )
                          }
                          onChange={(date) => {
                            // Update the local state
                            const newDisplayDate =
                              date?.toISOString() || new Date().toISOString();
                            setRecentlyUploadedDocuments(
                              recentlyUploadedDocuments.map((item, i) =>
                                index === i
                                  ? {
                                      ...item,
                                      displayDate: newDisplayDate,
                                    }
                                  : item
                              )
                            );
                            // Update the document in the system
                            updateDocumentMutation({
                              variables: {
                                id: file.id || "",
                                document: {
                                  name: file.name || "",
                                  description: file.description || "",
                                  type: file.type,
                                  displayDate: newDisplayDate,
                                },
                              },
                            });
                          }}
                        />
                      </StyledDatePickerWrapper>
                    </List.Content>

                    <Popup
                      content={file.mimetype}
                      key={index + "mime"}
                      header={"Skráartýpa"}
                      trigger={
                        <Icon
                          name={getFontAwesomeIconFromMIME(file.mimetype)}
                          size="big"
                        />
                      }
                    />
                    <List.Content>{file.name}</List.Content>
                  </List.Item>
                ))}
              </StyledListContent>
            </>
          )}
          <UploadContainer {...getRootProps()}>
            <input {...getInputProps()} onChange={onChange} />
            {isDragActive ? (
              <p>Dragðu skrárnar hingað...</p>
            ) : (
              <p>Dragðu skrár hingað, eða veldur skrár..</p>
            )}
            <Icon size="big" name="upload" />
          </UploadContainer>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            props.onUpdated();
            setRecentlyUploadedDocuments([]);
            setAssignmentChoices([]);
            props.close();
          }}
        >
          Loka
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DocumentUploadModal;
